<!--
 * @Author: fangting fangting@whty.com.cn
 * @Date: 2022-12-14 15:23:40
 * @LastEditors: fangting fangting@whty.com.cn
 * @LastEditTime: 2022-12-28 15:34:13
 * @FilePath: \web\src\views\invoicingManagement\invoiced\dialog\export.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <Dialog :config="config" :visible='visible' class="employ_msg" :title="title" :cancelDialog="cancelDialog" :confirmDialog='confirmDialogs' :isdeling='isdeling'>
      <div class="export_tip">导出选项</div>
      <el-radio-group v-model="exportType" class="export_select">
        <el-radio label="2">导出全部已开发票</el-radio>
        <el-radio label="1">导出选中已开发票</el-radio>
      </el-radio-group>

  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      title : {
        type: String,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      Dialog,
    },
    data() {
      return {
        config: {
          top: '25vh',
          width: '20rem',
          center: true,
          title: '导出',
          btnTxt: ['确定','取消'],
        },
        exportType:'2',
      };
    },
    methods: {
      confirmDialogs(){
        this.confirmDialog(this.exportType)
      }
    },
    watch:{
      
    }
  };
</script>

<style lang="less" scoped>
  .export_tip {
    text-align: center;
    font-size: 18px;
    margin-bottom: 35px;
  }
  .export_select {
    width: 100%;
    /deep/ label {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }
</style>
